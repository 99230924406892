const firebaseConfig = {
  apiKey: "AIzaSyB5s68-Ne3yJiXsBWS2J1DVqfG18ce1jhw",
  authDomain: "glaser-user-system.firebaseapp.com",
  projectId: "glaser-user-system",
  storageBucket: "glaser-user-system.appspot.com",
  messagingSenderId: "879238791093",
  appId: "1:879238791093:web:0eb263a661d5bbea1223fc",
  //measurementId: "G-4JD4SR4YNE",
  databaseURL: "https://glaser-user-system-default-rtdb.firebaseio.com"
};

export { firebaseConfig };