const DEBUG = 0;

import { defineStore } from 'pinia'

import { auth, db } from '@/firebase/';

import { set, ref, update, onValue } from 'firebase/database';

import cloneDeep from 'lodash/cloneDeep';

export const useUserStore = defineStore( 'user', {

  state: () => {
    return {
      uid: '',
      name: '',
      email: '',
      phone: '',
      address: '',
      inFirebase: false,
      inCRM: false,
      conversionsSent: false,
      newUser: true,
      savedListings: false,
      currentPaperwork: false,
      signedIn: false,
      signedOut: false, // for sign out message
      //registrationMethod: 'email',
    }
  },
  
  actions: {

    /**
     * @method exists
     * checks if user already exists in db. returns user data object or false
     * @param {firebase.auth.user} firebase auth user object
     * @async
     */

    async exists( user ){
      const userRef = ref( db, `users/${user.uid}` );
      const promise = new Promise((resolve, reject) => {   
        if( DEBUG > 4 ) console.log( 'Creating user.exists promise' );    
        try {       
          onValue( userRef, ( snapshot ) => {
            if( DEBUG > 4 ) console.log( 'onValue firing' );
            if( !snapshot.exists() ){
              if( DEBUG > 2 ) console.log( 'No snapshot found: registering new user' );
              this.register( user, userRef );
              reject( false );
            }
            else {
              if( DEBUG > 2 ) console.log( 'Snapshot found - signing user in' );
              this.signIn( snapshot.val(), userRef );
              resolve( snapshot.val() ); 
            }
          })
        } catch( e ){
          if( DEBUG ) console.log( 'onValue failed:', e );
        }
      })
      return promise;
    },
    
    /**
     * @method register
     * registers a new user in db
     * @param {firebase.auth.user} firebase auth user object
     * @param {firebase.db.ref} firebase db reference object to the user
     */

    register( firebaseUserObject, firebaseDbReference ){
      const u = firebaseUserObject;
      this.$patch(( userStoreState ) => {        
        if( u.uid ){          
          //create new user
          userStoreState.uid = u.uid;
          if( u.displayName ) userStoreState.name = u.displayName;
          if( u.phoneNumber ) userStoreState.phone = u.phoneNumber;
          if( u.photoURL ) userStoreState.photo = u.photoURL;
          if( u.email ) userStoreState.email = u.email;
        }
        else throw 'Cannot register a user without a valid uid.' ;
      });
      const $userState = this.$state;
      const userState = Object.assign( {}, $userState );
      //@DEVNOTE: just use deep conversion since everybody and their mama is using PROXY!!!
      userState.savedListings = JSON.parse( JSON.stringify( userState.savedListings ) );
      const addUser = set( firebaseDbReference, userState )
      addUser.then(()=>{ this.signedIn = true })
      return addUser;
    },

    /**
     * @method signIn
     * signs a user in. fills state with relevant user data. disables newUser flag if present
     * @param {firebase.db.snapshot.val} userData the user's data from firebase db
     * @param {firebase.db.ref} firebase db reference object to the user
     */

    signIn( userData, userRef ){
      if( DEBUG > 2 ) console.log( 'user.signIn retrieved db data:', userData );
      this.$patch(( userStoreState ) => {
        for( let i in userStoreState ){
          userStoreState[i] = userData[i];
        }
      });
      // disable newUser flag            
      if( userData.newUser ) update( userRef, { newUser: false });
      this.signedIn = true
    },





    /*
     * @method update
     * updates a single key-value pair for this user in firebase database. ignores unknown keys
     */

    update( key, value ){
      if( !this.signedIn ){
        if( DEBUG ) console.log( 'Cannot update: No user signed in.' );
        return;
      }
      if( key in this.$state ){
        if( typeof value !== 'undefined' ){
          let t = {};
          t[ key ] = value;
          return update( ref( db, `users/${this.uid}` ), t )
        }
      }
      return false;
    },





    /*
     * @method convertStateToObject
     * clones the entire state to remove reactivity; useful for storing in database
     */

    convertStateToObject(){      
      if( DEBUG ) console.log( 'convertStateToObject returning', cloneDeep( this.$state ) );
      return cloneDeep( this.$state );
    },





    /*
     * @method sendConversions
     * sends any and all required conversion actions
     */

    sendConversions(){

    },




    /*
     * @method addToCRM
     * sends user data to the CRM (Follow Up Boss)
     */

    addToCRM(){

    },






    /*
     * @method isSavedListing
     * returns boolean, true if in savedListings
     */

    isSavedListing( mlsId ){
      // console.log( typeof mlsId );
      const s = this.savedListings
      return ( s && ( s.indexOf( mlsId ) > -1 ) );
    },

    /*
     * @method addSavedListing
     * pushes mlsId to savedListings if not already present. updates db
     */

    addSavedListing( mlsId ){
      if( !this.savedListings ) this.savedListings = [];
      if( this.isSavedListing( mlsId ) ){
        if( DEBUG > 1 ) console.log( mlsId, 'already favorited: terminating.' );
        return;
      }
      this.savedListings.push( mlsId );
      if( DEBUG > 2 ) console.log( `Listing ${mlsId} added to savedListings` );
      return this.update( 'savedListings', this.savedListings );
    },

    /**
     * @method removeSavedListing
     * removes mlsId from savedListings. updates db
     */

    removeSavedListing( mlsId ){
      const s = this.savedListings;
      if( DEBUG > 2 ) console.log( 'removeSavedListing called on', mlsId, s );
      if( s && ( s.indexOf( mlsId ) > -1 ) ){
        this.savedListings.splice( s.indexOf( mlsId ), 1 );
        if( DEBUG > 2 ) console.log( `Listing ${mlsId} removed from savedListings (now)`, this.savedListings );
        // convert empty array to false to appease firebase
        if( this.savedListings.length == 0 ) this.savedListings = false;
        return this.update( 'savedListings', this.savedListings );
      }
      return false;
    },


    /**
     * @method signOut
     * signs a user out of the system
     */

    signOut(){      
      this.signedIn = false;
      this.signedOut = true;
      if( DEBUG > 3 ) console.log( 'Signed out state:', this.$state );
      auth.signOut();
      if( DEBUG ) console.log( 'User logged out.' );
      const store = this;
      //reset state after brief delay
      setTimeout( ()=>{
        // @DEVNOTE: having to do this because of Pinia error...
        store.$patch({
          uid: '',
          name: '',
          email: '',
          phone: '',
          address: '',
          inFirebase: false,
          inCRM: false,
          conversionsSent: false,
          newUser: true,
          savedListings: false,
          currentPaperwork: false,
          signedIn: false,
          signedOut: false, // for sign out message
          //registrationMethod: 'email',
        })
      }, 3000 );
    }

  },

})

/*
  state: () => {
    return { ...initialConfiguratorState };
  },
*/